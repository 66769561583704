.tabWrapper {
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  margin: 8px;
  background-color: #eee;
}

@media only screen and (min-width: 1500px) {
  .tabWrapper {
    width: 80%;
    max-width: 80%;
  }
}

@media only screen and (min-width: 1850px) {
  .tabWrapper {
    width: 50%;
    max-width: 50%;
  }
}

.licenceWrapper {
  width: 100%;
  border-radius: 15px;
  background-color: #ddd;
  padding: 20px;
  display: "flex";
  flex-direction: "column";
  justify-content: "flex-start";
  align-content: "flex-start";
  align-items: "flex-start";
}

@media only screen and (min-width: 1500px) {
  .licenceWrapper {
    width: 65%;
    max-width: 65%;
  }
}

@media only screen and (min-width: 1950px) {
  .licenceWrapper {
    width: 45%;
    max-width: 45%;
  }
}
