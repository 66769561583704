body {
  font-family: "Roboto";
}
.MuiTypography-root {
  font-family: "Roboto";
}
.MuiCardHeader-root {
  /* background-color: gray; */
  /* background-color: rgba(1, 73, 56, 0.8); */
  /* background-color: rgba(1, 73, 56, 0.8); */
  height: 70px;
  backdrop-filter: blur(10px);
}
.MuiCard-root {
  /* background-color: gray; */
  /* background-color: rgba(1, 73, 56, 0.4); */
  backdrop-filter: blur(10px);
}
.MuiCardHeader-title {
  font-family: "Roboto";
  margin: 0;
}
.MuiCardHeader-subheader {
  font-family: "Roboto";
  margin: 0;
  font-weight: 300;
}
.MuiTypography-h5 {
  font-weight: 300;
  font-family: "Roboto";
}
.MuiTypography-body1 {
  font-weight: 200;
  font-family: "Roboto";
}
.MuiTypography-body2 {
  font-weight: 300;
  font-family: "Roboto";
}
