@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');  */
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@media (prefers-color-scheme: dark) {
  body {
    @apply text-white bg-black;
  }

  input {
    @apply bg-gray-800 text-white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold;
  @apply mb-2;
  @apply select-none;
}

dl + h1,
dl + h2,
dl + h3,
dl + h4,
dl + h5,
dl + h6,
ul + h1,
ul + h2,
ul + h3,
ul + h4,
ul + h5,
ul + h6,
table + h1,
table + h2,
table + h3,
table + h4,
table + h5,
table + h6 {
  @apply mt-4;
}

table.table-striped tbody tr:nth-child(even) {
  background-color: #212121;
  border-radius: 15px;
}
table.table-striped tbody tr:nth-child(odd) {
  /* @apply bg-gray-800; */
  background-color: #424242;
  border-radius: 15px;
}

h1 {
  @apply text-5xl;
}

h2 {
  @apply text-4xl;
}

h3 {
  @apply text-3xl;
}

h4 {
  @apply text-2xl;
}

h5 {
  @apply text-xl;
}

h6 {
  @apply text-lg;
}

@media (prefers-color-scheme: light) {
  body {
    @apply bg-white text-black;
  }

  input {
    @apply bg-gray-50 text-black;
  }
}

body {
  @apply relative;
}

dl.horizontal {
  @apply grid grid-cols-3 gap-1;
}

dl.horizontal.center {
  @apply items-center;
}

dl dt {
  @apply select-none font-bold col-span-1;
  /*@apply col-span-12 md:col-span-4;*/
}

dl dd {
  @apply select-all tracking-wider break-words col-span-2;
  /*@apply col-span-12 md:col-span-8;*/
}
