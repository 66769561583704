.card {
  width: 200px;
  height: 80px;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 10px 10px;
  position: relative;
}

.main {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
}
.card::after {
  position: absolute;
  content: "";
  height: 20px;
  right: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 30px;
  background-color: #424242;
  width: 30px;
}

.card::before {
  position: absolute;
  content: "";
  height: 20px;
  left: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 30px;
  background-color: #424242;
  width: 30px;
}

.co-img img {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}
.vertical {
  border-left: 5px dotted black;
  height: 50px;
  position: absolute;
  left: 32%;
}
.content {
  max-width: 60%;
  position: initial;
  left: 10%;
}

.content h1 {
  font-size: 12px;
  margin: 0;
  padding: 0;
  /* margin-left: -60px; */
  color: #565656;
}

.content h1 span {
  font-size: 10px;
}
.content h2 {
  font-size: 10px;
  margin: 0;
  line-height: 1.5rem;
  /* margin-bottom: 5px; */
  /* margin-left: -60px; */
  color: #565656;
  text-transform: uppercase;
}

.content p {
  font-size: 10px;
  color: #696969;
  /* margin-left: -60px; */
}
