/* .abscentAvatarImg {
  filter: grayscale(90%);
}

.homeofficeAvatarImg {
  filter: grayscale(20%);
} */

.dashboardWrapper {
  width: 100%;
}

@media only screen and (min-width: 1500px) {
  .dashboardWrapper {
    width: 80%;
    max-width: 80%;
  }
}

@media only screen and (min-width: 1850px) {
  .dashboardWrapper {
    width: 60%;
    max-width: 60%;
  }
}
