.rbc-month-view {
  border: 3px solid #0f222e;
  border-radius: 20px;
  overflow: hidden;
}

.rbc-month-row {
  border-top: 1px solid #0f222e;
}

/* .rbc-off-range-bg {
  background-color: #c7c7c7;
}

.rbc-today {
  background-color: #d9d9d9;
}

.rbc-btn-group {
  background-color: azure;
} */
.body {
  color: black;
}
.MuiSelect-select,
.MuiSelect-multiple,
.MuiSelect-outlined,
.MuiSelect-standard,
.MuiSelect-iconStandard {
  border-color: black;
  border: black;
  color: "#000";
}

.MuiSelect-icon {
  color: "#000";
}

/* .rbc-addons-dnd-resizable {
  display: flex;
}
.rbc-addons-dnd-resize-ew-icon::before {
  content: " || ";
} */

/* .rbc-month-header {
    background-color: #0a151c;
} */
